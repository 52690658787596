<template>
    <form class="row g-1 p-0 p-md-4" method="GET" @submit.prevent="submit">       
    <div class="col-12 text-center mb-5">
        <!-- <img class="img-fluid m-auto" width="30%" src="/images/logo2T.png" alt="">   -->
        <h5><b>INICIAR SESION</b></h5>
    </div>
    <!-- <div class="col-12 text-center mb-4">
        <a class="btn btn-lg btn-outline-secondary btn-block" href="#">
            <span class="d-flex justify-content-center align-items-center">
                <img class="avatar xs mr-2" src="../../../assets/images/google.svg" alt="Image Description">
                Sign in with Google
            </span>
        </a>
        <span class="dividers text-muted mt-4">OR</span>
    </div> -->
    <div class="col-12">
        <div class="mb-2">
            <label class="form-label">Email</label>
            <input required v-model="user.nombreusuario" type="email" class="form-control form-control-lg" placeholder="name@example.com">
        </div>
    </div>
    <div class="col-12">
        <div class="mb-2">
            <div class="form-label">
                <span class="d-flex justify-content-between align-items-center">
                    Contraseña
                    <router-link class="text-primary" to="/authentication/auth-password-reset">Olvidaste tu contraseña?</router-link>
                </span>
            </div>
            <input required v-model="user.clave" type="password" class="form-control form-control-lg" placeholder="***************">
        </div>
    </div>
    <div class="col-12">
        <div class="form-check">
            <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault">
            <label class="form-check-label" for="flexCheckDefault">
                Recuerdame
            </label>
        </div>
    </div>
    <div class="col-12 text-center mt-4">
        <button type="submit" class="btn btn-lg btn-dark lift text-uppercase">INICIAR SESION</button>
        <p @click="$router.push('/authentication/auth-signup')" class="mt-3 pointer text-primary">No tienes cuenta? registrate!!</p>
        <p class="mt-3 pointer text-primary">V230723</p>
    </div>
    <div class="col-12 text-center mt-4">
        <!-- <span class="text-muted">Don't have an account yet? <router-link to="/authentication/auth-signup">Sign up here</router-link></span> -->
    </div>
</form>

</template>
<script>
// import { encrypt, decrypt } from "ncrypt-js";
// import ncrypt from "ncrypt-js";

export default {
    name:'Sign_inComponent',
    data(){
        return{
            user:{
                nombreusuario:"", //, "abr@siscoper.net",
                clave:"", //,  "Mecatestudio_9090", // "creasa2020", //"Mecatestudio_2323"  "Mecatestudio_9090",
            }
        }
    },
    methods: {
        async submit(e){
            this.$isLoading(true)            
            try {
                let res = await this.$store.dispatch('login', {data: this.user});
                this.$isLoading(false)                
                if (res.data ){
                    let user = res.data
                    //this.$store.commit('setToken', res.token)
                    //this.$store.commit('setUser', res.data)
                    // this.$router.push('/home')
                    localStorage.setItem('company_id', user.empresa_id)
                    localStorage.setItem('user', JSON.stringify(user))
                    localStorage.setItem('rol', user.rol)
                    localStorage.setItem('user_id', user.id)
                    localStorage.setItem('urllogo', user.url_logo)
                    localStorage.setItem('sucursal_id', user.sucursal_id)
                    localStorage.setItem('nombreusuario', user.nombreusuario)
                    localStorage.setItem('plan', user.plan_id)
                    localStorage.setItem('iva', user.iva)
                    localStorage.setItem('moneda', user.moneda)
                    const newUser = { uid: user.id };
                    localStorage.setItem("userInfo", JSON.stringify(newUser));
                    let textos = {
                        type : res.account_type,
                        txtin: res.account_type == 'Personal' ? 'Ingresos': 'Ventas',
                        txtout: res.account_type == 'Personal' ? 'Gastos': 'Compras'
                    }
                    localStorage.setItem('account', JSON.stringify(textos))

                    console.log('typeacc', res.account_type)

                    //let { encodeData } = new ncrypt('Mecatestudionic');

                    // var { encrypt, decrypt } = require("ncrypt-js");
                    // var ncrypt = require("ncrypt-js");
                    
                    
                    // var data = "Hello World!";
                    // var _secretKey = "some-super-secret-key";
                    
                    // // var { encodeData, decodeData } = new ncrypt(_secretKey);
                    
                    // // encrypting super sensitive data here
                    // var encryptedData = encrypt(data, _secretKey);
                    // // var encryptedData = encrypt(data);
                    
                    // console.log("Encryption process...");
                    // // console.log("Plain Text    : " + data);
                    // console.log("Cipher Text   : " + encryptedData);
                    
                    // // decrypted super encrypted string here
                    // var decryptedData = decrypt(encryptedData);
                    // console.log("... and then decryption...");
                    // console.log("Decipher Text : " + decryptedData);
                    // console.log("...done.");
















                    // let configuration = await this.$store.dispatch('get', {path:'usuarios/configuracion/'+user.empresa_id});
                    // localStorage.setItem('dataCompany', JSON.stringify(encrypt.encrypt(configuration, 'Mecatestudionic')))
                    // let de = decrypt.decrypt(JSON.parse(localStorage.getItem('dataCompany')))
                    // console.log(de)
                    // if(user.rol =='Administrador' || user.rol == 'SuperAdmin'){
                    //     this.$router.push("/app/notifications")
                    // }
                    // else{
                    //     this.$router.push("/app/purchases-sales/SalesDetail")
                    // }
                    this.$router.push({ path: '/dashboard/index' })
                    e.preventDefault()
                }
                else{
                    this.$swal({title:"Aviso", text:"Error: problema con credenciales.(cod:15)"  , icon:"error"})
                }
            } catch (error) {
                this.$isLoading(false)
                this.$swal({title:"Aviso", text:"Error: problema con credenciales.(cod:55)"  , icon:"error"})
            }
        }
    }
}
</script>
<style scoped>

</style>